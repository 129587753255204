<template>
    <div class="query-field-ware">
        <div class="query-field">
            <!-- name -->
            <span class="field-word">{{getRealName(tempQueryParam.ctId, 1)}}</span>
            <!-- 时间 -->
            <span class="field-word" v-if="tempQueryParam.start && !tempQueryParam.end">{{tempQueryParam.start}}到现在</span>
            <span class="field-word" v-if="tempQueryParam.start && tempQueryParam.end">{{tempQueryParam.start}}到{{tempQueryParam.end}}</span>
            <span class="field-word" v-if="!tempQueryParam.start && tempQueryParam.end">截止到{{tempQueryParam.end}}</span>
            <span class="field-word" v-if="(tempQueryParam.ctId || tempQueryParam.start || tempQueryParam.end) && tempQueryParam.paperId">的</span>
            <span class="field-word" v-if="tempQueryParam.paperId">{{getRealName(tempQueryParam.paperId, 2)}}</span>
            <i class="iconfont icon-close pull-right reset-query" @click="$emit('resetQuery')"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'QueryField',
    props: {
        tempQueryParam: {
            type: Object
        },
        allUserList: {
            type: Array
        },
        lookNameList: {
            type: Array
        },
        writeNameList: {
            type: Array
        },
        subordinateList: {
            type: Array
        },
        tabData: {
            type: Number
        }
    },
    components: {},
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        getRealName(id, n) {
            if (id) {
                if (n === 1) return this.allUserList.filter(v => v.ctId === id)[0].realName
                if (n === 2) {
                    if (this.tabData === 0) {
                        return this.lookNameList.filter(v => v.paperId === id)[0].name
                    } else if (this.tabData === 1) {
                        return this.writeNameList.filter(v => v.paperId === id)[0].name
                    } else if (this.tabData === 2) {
                        return this.subordinateList.filter(v => v.paperId === id)[0].name
                    }
                }
            }
        }
    },
    beforeDestroy() { },
    beforeRouteLeave(to, from, next) {
        next()
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
</style>
